var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('h1',[_vm._v("Gemeldete Turnerinnen für "+_vm._s(_vm.teamname)+":")]),_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"items":_vm.teamathletes,"headers":[
        { text: 'Nachname', value: 'familyName' },
        { text: 'Vorname', value: 'givenName' },
        { text: 'DTB-ID', value: 'dtbid', sortable: false },
        { text: 'Startmarke für', value: 'turnportal.club' },
        { text: 'Gültig ab', value: 'turnportal.validFrom' },
        { text: 'Gültig bis', value: 'turnportal.validUntil' },
        { text: 'Gesperrt', value: 'turnportal.banned' },
        { text: 'Letzte Überprüfung', value: 'turnportal.lastCheck' },
        { text: 'Geräte in DTL 2022*', value: 'dtlgeraete', align: 'center', sortable: false },
        { text: 'Bodenmusik**', value: 'music', sortable: false }
      ],"sort-by":"familyName","items-per-page":-1},scopedSlots:_vm._u([{key:"item.turnportal.validFrom",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.turnportal.validFrom))+" ")]}},{key:"item.turnportal.validUntil",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.turnportal.validUntil))+" ")]}},{key:"item.turnportal.banned",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.turnportal.banned ? 'JA!' : 'nein')+" ")]}},{key:"item.turnportal.lastCheck",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateformat")(item.turnportal.lastCheck))+" ")]}},{key:"item.dtlgeraete",fn:function(ref){
      var item = ref.item;
return [_c('div',{style:({ background: item.dtlgeraete === null ? '#fc9895' : (item.dtlauthorized ? '#a2fca5' : '#fad26b') , 'border-radius':'4px' })},[_c('base-edit-dialog',{attrs:{"value":item.dtlgeraete === null ? '' : item.dtlgeraete,"id":item._id,"label":"Geräte in DTL"},on:{"input":function (ref) {
            var value = ref.value;
            var id = ref.id;

            return _vm.savedtl(id, value);
      }}})],1)]}},{key:"item.music",fn:function(ref){
      var item = ref.item;
return [(!item.music)?_c('vue-dropzone',{ref:"attachments",staticClass:"stbw2023teamturnermusicdropzone",attrs:{"id":"attachments","options":Object.assign({}, _vm.dropzoneOptions, {accept: function (file) { return _vm.addAttachment(file, item._id); }})}}):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('a',{attrs:{"href":("" + _vm.imageBase + (item.music._id)),"download":item.music.filename,"target":"_blank"}},[_c('v-icon',[_vm._v("far fa-download")])],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.music.filename))])]),_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){return _vm.delAttachment(item._id)}}},[_c('v-icon',[_vm._v("far fa-trash-alt")])],1)],1)]}}])})],1),_c('p',[_c('i',[_vm._v("* bitte geben Sie an, wie viele Geräteeinsätze die Turnerinnen in der DTL in der Vorsaison hatten. Hatte eine Turnerin einen Einsatz in der DTL, so muss dieser zuerst vom Ligabeauftragten freigegeben werden, das Feld ist dann orange.")])]),_c('p',[_c('i',[_vm._v("** laden Sie hier die Bodenmusik für die jeweilige Turnerin ins System. Erlaubt sind nur mp3-Dateien. Ziehen Sie die Datei per Drag&Drop auf das Feld oder klicken Sie auf das Feld um anschließend die Datei auswählen zu können. Soll eine Datei ausgetaucht werden, so muss die alte zunächst gelöscht werden.")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }